import React, {useContext, useEffect, useMemo, useState} from 'react';
import {useFetching} from "../hooks/useFetching";
import ClientService from "../services/ClientService";
import {Context} from "../index";
import cl from '../pages/styles/ClientEntries.module.css'
import {formatDate} from "../UI/date/dateFormat";
import Loader from "../UI/loader/Loader";
import MyModal from "../UI/modal/MyModal";
import {findAllByDisplayValue} from "@testing-library/react";
import MyButton from "../UI/button/MyButton";

const ClientMeetings = ({typeOfMeeting}) => {
    const {store} = useContext(Context)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const now = new Date()
    const [backendResponse, setBackendResponse] = useState('')
    const [modalIsActive, setModalIsActive] = useState(false)
    const [allActiveMeetings, setAllActiveMeetings] = useState([])
    const [allEntries, setAllEntries] = useState([])
    const [consInfo, setConsInfo] = useState({})
    const [filterEntries, setFilterEntries] = useState('actual')
    const [allMeetingIsActive, setAllMeetingIsActive] = useState(false)
    const [allEntriesIsActive, setAllEntriesIsActive] = useState(false)
    const [fetchAllActiveMeetings, allActiveMeetingsIsLoading, allActiveMeetingsError] = useFetching(
        async () => {
            const response = await ClientService.getFreeMeetings(typeOfMeeting, store.userID)
            setAllActiveMeetings(response)
        })

    const [fetchConsInfo, consInfoIsLoading, consInfoError] = useFetching(
        async () => {
            const response = await ClientService.getConsInfo(typeOfMeeting, store.userID)
            setConsInfo(response)
        })

    const [fetchAllEntries, allEntriesIsLoading, allEntriesError] = useFetching(
        async () => {
            const response = await ClientService.getMeetingEntries(typeOfMeeting, store.userID)
            setAllEntries(response)
        })

    const filteredEntries = useMemo(() => {
        if (filterEntries === 'actual') {
            return allEntries.filter(entry => (
                Date.parse(entry.meeting_end_date) > Date.parse(now)
            ))
        }
        else if (filterEntries === 'nonactual') {
            return allEntries.filter(entry => (
                Date.parse(entry.meeting_end_date) < Date.parse(now)
            ))
        }
    }, [allEntries, filterEntries]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        fetchAllActiveMeetings()
        fetchConsInfo()
        fetchAllEntries()
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setBackendResponse('')
        if (allActiveMeetingsError.length) {
            setBackendResponse(allActiveMeetingsError)
            setModalIsActive(true)
        }
        else if (consInfoError.length) {
            setBackendResponse(consInfoError)
            setModalIsActive(true)
        }
        else if (allEntriesError.length) {
            setBackendResponse(allEntriesError)
            setModalIsActive(true)
        }
    }, [allActiveMeetingsError, consInfoError, allEntriesError]);


    const [agree, setAgree] = useState(false)
    const [queryData, setQueryData] = useState({
        client_id: store.userID,
        query_type: typeOfMeeting,
        content: ''
    })
    const [fetchAddQuery, addQueryIsLoading, addQueryError] = useFetching(
        async () => {
            setModalIsActive(false)
            if (agree) {
                if (queryData.content.length >= 7) {
                    const response = await ClientService.addQuery(queryData)
                    setBackendResponse(response)
                }
                else {
                    setBackendResponse('Опишите свой запрос более ёмко, пожалуйста.')
                }
            }
            else {
                setBackendResponse('Необходимо дать согласие на обработку персональных данных')
            }
            setModalIsActive(true)
        })


    if (Object.keys(consInfo).length) {
        return (

            <div className={cl.meetings}>
                <h3>{`Информация о прикрепленном ${typeOfMeeting === 'rk' ? 'равном консультанте' : 'психологе'}`}</h3>
                <div className={cl.cons_info}>
                    <div>
                        <p>Имя:</p>
                        <h5>{consInfo.name}</h5>
                    </div>
                    <hr/>
                    <div>
                        <p>Фамилия:</p>
                        <h5>{consInfo.surname}</h5>
                    </div>
                    <hr/>
                    <div>
                        <p>{consInfo.priority_link === 'telegram' ? 'Telegram:' : 'Телефон:'}</p>
                        <h5>{consInfo.priority_link === 'telegram' ? `@${consInfo.telegram_id}` : consInfo.phone}</h5>
                    </div>
                    <hr/>
                    <div>
                        <p>Описание:</p>
                        <h5>{consInfo.description}</h5>
                    </div>
                </div>

                <div className={cl.meetings_buttons}>
                    <button
                        onClick={() => {
                            setAllEntriesIsActive(false)
                            setAllMeetingIsActive(!allMeetingIsActive)
                        }}
                    >Найти встречу</button>
                    <button
                        onClick={() => {
                            setAllMeetingIsActive(false)
                            setAllEntriesIsActive(!allEntriesIsActive)
                        }}
                    >Ваши встречи</button>
                </div>

                <div className={allMeetingIsActive ? cl.plug : cl.invisible}>
                    <h3>Список свободных слотов</h3>
                    {allActiveMeetings.length
                        ? allActiveMeetings.map((meeting) => (
                            <div
                                key={meeting.id}
                            >
                                <a
                                    className={cl.meeting_href}
                                    href={`/client/meeting/${meeting.id}`}>
                                    {`${meeting.name} ${formatDate(meeting.meeting_start_date)}`}
                                </a>
                            </div>
                        ))
                        : <h5>К сожалению, сейчас нет доступных слотов. Новые слоты добавляются по выходным.
                            Если вы давно ждёте или вам нужна срочная консультация,
                            напишите нашему администратору в
                            <a
                                style={{color: "teal"}}
                                target="_blank"
                                rel="noreferrer"
                                href="https://t.me/eq_tg_bot"> telegram</a>
                        </h5>
                    }
                </div>

                <div className={allEntriesIsActive ? cl.plug : cl.invisible}>
                    <h3>Ваши встречи</h3>
                    <div className={cl.entries_radio}>
                        <div>
                            <input
                                type="radio"
                                name='change'
                                id='actual'
                                defaultChecked={true}
                                onChange={() => {
                                    setFilterEntries('actual')
                                }}
                            />
                            <label htmlFor="actual">Актуальные</label>
                        </div>
                        <div>
                            <input
                                type="radio"
                                name='change'
                                id='nonactual'
                                onChange={() => {
                                    setFilterEntries('nonactual')
                                }}
                            />
                            <label htmlFor="actual">Прошедшие</label>
                        </div>
                    </div>
                    {filteredEntries.length
                        ? filteredEntries.map((entry) => (
                            <div
                                key={entry.id}
                            >
                                <a
                                    className={cl.meeting_href}
                                    href={`/client/meeting/${entry.meeting_id}`}>
                                    {`${entry.meeting_name} ${formatDate(entry.meeting_start_date)}`}
                                </a>
                            </div>
                        ))
                        : <p>Записи не найдены</p>

                    }
                </div>

                {(allActiveMeetingsIsLoading || consInfoIsLoading || allEntriesIsLoading) &&
                    <div><Loader/></div>
                }

                {modalIsActive &&
                    <div>
                        <MyModal
                            active={modalIsActive}
                            setActive={setModalIsActive}
                            children={backendResponse}
                        />
                    </div>
                }

            </div>
        );
    }

    else {
        return (
            <div className={cl.query}>
                <textarea
                    placeholder='Опишите ваш запрос'
                    maxLength={500}
                    onChange={(event) => {
                        setQueryData({...queryData, content: event.target.value})
                    }}
                ></textarea>
                <div>
                    <input
                        type="checkbox"
                        id='checkbox'
                        value={agree}
                        onChange={() => {
                            setAgree(!agree)
                        }}
                    />
                    <label htmlFor="checkbox">Даю <a
                        className={cl.href}
                        target="_blank"
                        href="/agreement">согласие на обработку персональных данных</a>
                    </label>
                </div>
                <button onClick={() => {
                    fetchAddQuery()
                }}>Отправить</button>

                {addQueryIsLoading
                    ? <div><Loader/></div>
                    : <div>
                        <MyModal
                            active={modalIsActive} setActive={setModalIsActive} children={backendResponse}/>
                    </div>
                }
            </div>
        )
    }


};

export default ClientMeetings;