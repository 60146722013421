import api from "../http";


export default class ClientService {
    static async getFreeGroups() {
        const response = await api.get('/clients/get_free_groups')
        return response.data
    }

    static async getClientGroup() {
        const response = await api.get('/clients/get_client_groups')
        return response.data
    }
    static async addBeckScaleResult(beckData) {
        const response = await api.post('/clients/add_result_beck_scale', beckData)
        return response.data
    }

    static async activateAccount(activateID) {
        const response = await api.get(`/users/activation/${activateID}`)
        return response.data
    }

    static async getFreeMeetings(meetingsType, clientID) {
        const response = await api.get(`/clients/get_free_meetings/${meetingsType}&${clientID}`)
        return response.data
    }

    static async getConsInfo(consType, clientID) {
        const response = await api.get(`/clients/get_cons_info/${consType}&${clientID}`)
        return response.data
    }

    static async getMeetingInfo(meetingID) {
        const response = await api.get(`/clients/get_meeting_info/${meetingID}`)
        return response.data
    }

    static async getMeetingEntries(meetingsType, clientID) {
        const response = await api.get(
            `/clients/get_all_meeting_entry/${meetingsType}&${clientID}`)
        return response.data
    }

    static async addQuery(queryData) {
        const response = await api.post('/clients/add_client_query', queryData)
        return response.data
    }

    static async getPossibilityPsychoHelp(clientID) {
        const response = await api.get(
            `/clients/check_possibility_of_psycho_help/${clientID}`)
        return response.data
    }
}