import React, {useContext} from 'react';
import cl from "../pages/styles/AdminUserCard.module.css";
import Loader from "../UI/loader/Loader";
import Config from '../cfg'
import {Context} from "../index";
import MySelect from "../UI/select/MySelect";

const AdminUserCardById = ({user, isLoading, setUser, rkList, psychoList, allPsychoData, allRKData}) => {

    // eslint-disable-next-line no-unused-vars
    let userRole = user.role_id
    const {store} = useContext(Context)

    return (
        <div>
            {isLoading
                ? <div className={cl.loader}><Loader/></div>

                : <div>
                    <h3 className={cl.cardholder}>Карточка пользователя</h3>
                    <div className={cl.client}>
                        <ul>
                            <li>
                                <p>Имя</p>
                                <h5>{user.name}</h5>
                            </li>
                            <hr/>

                            <li>
                                <p>Фамилия</p>
                                <h5>{user.surname}</h5>
                            </li>
                            <hr/>

                            <li>
                                <p>Телефон</p>
                                <h5>{user.phone}</h5>
                            </li>
                            <hr/>

                            <li>
                                <p>Телеграм</p>
                                <h5><a
                                    target={"_blank"}
                                    href={`https://t.me/${user.telegram_id}`}>@{`${user.telegram_id}`}</a></h5>
                            </li>
                            <hr/>

                            <li>
                                <p>Диагноз</p>
                                <h5>{user.diagnosis}</h5>
                            </li>
                            <hr/>

                            <li>
                                <p>РК</p>
                                <MySelect
                                        options={rkList}
                                        defaultValue={user.rk_name ? user.rk_name : 'Не назначен'}
                                        value={user.rk_name ? user.rk_name : 'Не назначен'}
                                        onChange={(event) => {

                                            setUser({...user, rk: allRKData[event], rk_name: event})
                                        }}
                                    />

                            </li>
                            <hr/>

                            <li>
                                <p>Психолог</p>
                                    <MySelect
                                        options={psychoList}
                                        defaultValue={user.psycho_name ? user.psycho_name : 'Не назначен'}
                                        value={user.psycho_name ? user.psycho_name : 'Не назначен'}
                                        onChange={(event) => {
                                            console.log(allPsychoData[event])
                                            setUser({...user, psycho: allPsychoData[event], psycho_name: event})
                                        }}
                                    />
                            </li>
                            <hr/>

                            <li>
                                <p>Роль</p>
                                <div className={cl.radio}>
                                    <div>
                                        <input
                                            type="radio"
                                            name='role'
                                            value={Config.consultantID}
                                            id='role1'
                                            defaultChecked={user.role_id === Config.consultantID}
                                            onChange={(event) => {
                                                setUser({...user, role_id: event.target.value})
                                                userRole = event.target.value
                                            }}
                                        />
                                        <label htmlFor="role1">Консультант</label>
                                    </div>
                                    <div>
                                        <input
                                            type="radio"
                                            name='role'
                                            value={Config.clientID}
                                            id='role2'
                                            defaultChecked={user.role_id === Config.clientID}
                                            onChange={(event) => {
                                                setUser({...user, role_id: event.target.value})
                                                userRole = event.target.value
                                            }}
                                        />
                                        <label htmlFor="role2">Клиент</label>
                                    </div>
                                    <div>
                                        <input
                                            type="radio"
                                            name='role'
                                            value={Config.adminID}
                                            disabled={!store.isSuperUser}
                                            id='role3'
                                            defaultChecked={user.role_id === Config.adminID}
                                            onChange={(event) => {
                                                setUser({...user, role_id: event.target.value})
                                                userRole = event.target.value
                                            }}
                                        />
                                        <label htmlFor="role3">Админ</label>
                                    </div>
                                </div>
                            </li>
                            <hr
                                className={user.role_id === Config.consultantID ? cl.plug : cl.invisible}/>
                            <li>
                                <p className={user.role_id === Config.consultantID ? cl.plug : cl.invisible}>
                                    Тип роли
                                </p>
                                <div className={user.role_id === Config.consultantID ? cl.radio : cl.invisible}>
                                    <div>
                                        <input
                                            type="radio"
                                            name='spec'
                                            value='rk'
                                            id='spec1'
                                            defaultChecked={user.type_of_activity === 'rk'}
                                            onChange={(event) => {
                                                setUser({...user, type_of_activity: event.target.value})
                                            }}
                                        />
                                        <label htmlFor="spec1">РК</label>
                                    </div>
                                    <div>
                                        <input
                                            type="radio"
                                            name='spec'
                                            value='psycho'
                                            id='spec2'
                                            defaultChecked={user.type_of_activity === 'psycho'}
                                            onChange={(event) => {
                                                setUser({...user, type_of_activity: event.target.value})
                                            }}
                                        />
                                        <label htmlFor="spec2">Психолог</label>
                                    </div>
                                    <div>
                                        <input
                                            type="radio"
                                            name='spec'
                                            value='group_leader'
                                            id='spec3'
                                            defaultChecked={user.type_of_activity === 'group_leader'}
                                            onChange={(event) => {
                                                setUser({...user, type_of_activity: event.target.value})
                                            }}
                                        />
                                        <label htmlFor="spec3">Ведущий</label>
                                    </div>
                                </div>
                            </li>
                            <hr/>
                            <li>
                                <p>Верифици- рован</p>
                                <div className={cl.radio}>
                                    <div>
                                        <input
                                            type="radio"
                                            name='verified'
                                            value={true}
                                            id='verified1'
                                            defaultChecked={user.is_verified === true}
                                            onChange={(event) => {
                                                setUser({...user, is_verified: event.target.value})
                                            }}
                                        />
                                        <label htmlFor="active1">Да</label>
                                    </div>
                                    <div>
                                        <input
                                            type="radio"
                                            name='verified'
                                            value={false}
                                            id='verified2'
                                            defaultChecked={user.is_verified === false}
                                            onChange={(event) => {
                                                setUser({...user, is_verified: event.target.value})
                                            }}
                                        />
                                        <label htmlFor="active2">Нет</label>
                                    </div>
                                </div>
                            </li>
                            <hr/>
                            <li>
                                <p>Профиль активен</p>
                                <div className={cl.radio}>
                                    <div>
                                        <input
                                            type="radio"
                                            name='active'
                                            value={true}
                                            id='active1'
                                            defaultChecked={user.is_active === true}
                                            onChange={(event) => {
                                                setUser({...user, is_active: event.target.value})
                                            }}
                                        />
                                        <label htmlFor="active1">Да</label>
                                    </div>
                                    <div>
                                        <input
                                            type="radio"
                                            name='active'
                                            value={false}
                                            id='active2'
                                            defaultChecked={user.is_active === false}
                                            onChange={(event) => {
                                                setUser({...user, is_active: event.target.value})
                                            }}
                                        />
                                        <label htmlFor="active2">Нет</label>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            }
            {/*<button onClick={() => {console.log(user)}}>sds</button>*/}
        </div>
    );
};

export default AdminUserCardById;