import React, {useContext, useEffect, useState} from 'react';
import cl from './styles/ClientEntries.module.css'
import {useNavigate} from "react-router-dom";
import {useFetching} from "../hooks/useFetching";
import ClientService from "../services/ClientService";
import {Context} from "../index";


const ClientEntries = () => {
    const navigate = useNavigate()
    const {store} = useContext(Context)

    const [possibilityOfPsychoHelp, setPossibilityOfPsychoHelp] = useState(false)

    const [fetchPossibilityPsychoHelp, possibilityPsychoHelpIsLoading, possibilityPsychoHelpError] =
        useFetching(async () => {
            const response = await ClientService.getPossibilityPsychoHelp(store.userID)
            setPossibilityOfPsychoHelp(response)
        })

    useEffect(() => {
        fetchPossibilityPsychoHelp()
    }, []);

    return (
        <div className={cl.entries}>
            <div>
                <h4>Добрый день!
                    Добро пожаловать в благотворительный фонд «Равновесие».
                    Ваш аккаунт успешно верифицирован, и теперь вы
                    можете записаться на наши программы, найти их можно в
                    меню этого сервиса или по кнопкам ниже.

                    Если у вас появятся вопросы, напишите нам в <a
                        style={{color: "teal"}}
                        target="_blank"
                        rel="noreferrer"
                        href="https://t.me/eq_tg_bot"> telegram</a>
                </h4>
            </div>

            <button
                onClick={() => {
                    navigate('/client/groups')
                }}
            >Группы поддержки
            </button>
            <button
                onClick={() => {
                    navigate('/client/meetings_rk')
                }}
            >Запись к равному консультанту
            </button>
            <button
                className={possibilityOfPsychoHelp ? cl.plug : cl.invisible}
                onClick={() => {
                    navigate('/client/meetings_psychologist')
                }}
            >Запись к психологу
            </button>
        </div>
    );
};

export default ClientEntries;